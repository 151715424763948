<template>
    <div style="height:100%;">
      <scale-box>
        <div class="header">
            <div class="selectRange selectRange-left">
              <div class="header-logo" style="font-size: 14px!important;">
                <img style="width: 100%;height: 100%;" src="https://image.yuetaixing.com//crmebimage/public/maintain/2022/12/14/76d180f7f1e540deab868c759e105ea87hzrd1788f.png">
              </div>
            </div>

            <div class="header-title">
                群英会大数据中心
            </div>
<!--            <div class="selectRange selectRange-right">-->
<!--              -->
<!--            </div>-->
        </div>
        <div class="page">
<!--            <router-view v-if="flag" :selectRangeDate='selectRangeDate'></router-view>-->
          <router-view></router-view>
        </div>
      <div class="bottom">
        <Menu mode="horizontal" @on-select="(name) =>$router.push(name)" :active-name="$route.name">
          <MenuItem name="page1">
            <div class="title_header" style="font-size: 18px!important;letter-spacing: 5px!important;">首页</div>
          </MenuItem>
          <MenuItem name="page2">
            <div class="title_header">妇女儿童关怀</div>
          </MenuItem>
          <MenuItem name="page3">
            <div class="title_header">教育公益</div>
          </MenuItem>
            <MenuItem name="page4">
              <div class="title_header">医疗健康</div>
            </MenuItem>
            <MenuItem name="page5">
              <div class="title_header">社会工作</div>
            </MenuItem>
            <MenuItem name="page6">
              <div class="title_header">乡村振兴</div>
            </MenuItem>
            <MenuItem name="workVideo">
              <div class="title_header">灾害管理</div>
            </MenuItem>
        </Menu>
      </div>
      </scale-box>
    </div>
</template>

<script>
import scaleBox from "@/views/components/scaleBox.vue";
export default {
    name: '',
  components:{
    scaleBox
  },
    data() {
        return {
            selectRangeDate: [],
            optionEnd: {},
            resizeFn: null
        }
    },
    mounted() {
    },
    methods: {
    },
}
</script>

<style lang="less">
.ivu-modal {
    .ivu-modal-content {
        background: #071332;

        .ivu-modal-header {
            border-bottom: 1px solid #1a3c58;

            .ivu-modal-header-inner {
                color: #75deef;
            }
        }

        .ivu-modal-body {
            text-align: center;

            .ivu-icon {
                color: #75deef
            }

            .ivu-modal-confirm-body {
                padding-left: 0;
                color: #75deef
            }

            .ivu-input {
                background-color: rgba(0, 0, 0, 0);
                border: 1px solid #1a3c58;
                color: #75deef;

                &::-webkit-input-placeholder { /* WebKit, Blink, Edge */
                    color: #75deef;
                }

                &::-moz-placeholder { /* Mozilla Firefox 4 to 18 */
                    color: #75deef;
                }

                &::-moz-placeholder { /* Mozilla Firefox 19+ */
                    color: #75deef;
                }

                &::-ms-input-placeholder { /* Internet Explorer 10-11 */
                    color: #75deef;
                }
            }

            .ivu-picker-panel-body {
                background: #071332;

                .ivu-date-picker-header {
                    color: #75deef;
                    border-bottom: 1px solid #1a3c58
                }

                .ivu-date-picker-cells-cell {
                    color: #75deef;

                    &:hover em {
                        background: #1a3c58;
                    }
                }

                .ivu-date-picker-cells-cell-disabled {
                    background: rgba(0, 0, 0, 0);
                    color: #eee
                }

                .ivu-date-picker-cells-focused em {
                    box-shadow: 0 0 0 1px #1a3c58 inset;

                    &::after {
                        background: #1a3c58;
                    }
                }
            }
        }

        .ivu-modal-footer {
            border-top: 1px solid #1a3c58;

            .ivu-btn-primary {
                color: #75deef;
                background: #1a3c58;
            }

            .ivu-btn-text {
                color: #ddd;

                &:hover {
                    color: #75deef;
                    background: #1a3c58;
                }
            }
        }
    }

}

.header {
    height: 8%;
  margin-top: 0.5%;
    background: #03044A;
    display: flex;
    box-sizing: border-box;
    justify-content: space-between;
    align-items: center;
    //background: url("../assets/top.png") no-repeat fixed center;
    background-image: url('../assets/top.png');
    background-repeat: no-repeat;
    background-size: 100%;
  .header-title{
    width: auto;
    margin: 0 auto;
    padding-right: 5%;
    padding-bottom: 1%;
  }

    &-title {
        color: #75deef;
        font-size: 30px;
        letter-spacing: 10px;
    }
  //.selectRange-left{
  //  margin-left: 25px;
  //}
  .selectRange-right{
    margin-right: 20px;
  }
    .selectRange{
        height: 72%;
        padding-left: 1%;
        display: flex;
        align-items: center;
      .header-logo{
        //border-radius: 5px;
        //background-color: #fff;
        width: 90px;
        height: 105%;
        margin:16% 0 0 5%;
        img{
          width: 100%;
          height: 100%;
        }
        //z-index: 99;
      }
    }
}

.bottom{
  width: 90%;
  margin: 0 auto;
  height: auto;
  padding-bottom:2%;
  background: #03044A;
  .ivu-menu-horizontal {
    height: 60px;
    //background: #03044A;
    background: rgba(255, 255, 255, 0);
    display: flex;
    justify-content: space-around;
    &::after {
      height: 0px!important;
    }

    .ivu-menu-item-active {
      border: 2px solid #51b8e1;
    }

    .ivu-menu-item, .ivu-menu-submenu {
      color: #75deef;
      //background: #2768e3;
      font-size: 2px!important;
      background: radial-gradient(#5682e0, #154391);
      margin-top: 1%;
      width: 10%;
      line-height: 40px;
      height:40px;
      box-sizing: border-box;
      text-align: center;
      .title_header{
        width: 100%;
        height: 80%;
        color: #75deef;
        font-size: 16px;
        text-align: center;
        letter-spacing: 1px;
      }

      &:hover {
        border: 2px solid #51b8e1;
        //background: radial-gradient(3rem 0.9rem ellipse, rgba(7, 9, 34, 0.8) 60%, #154391);
      }
    }

    .ivu-select-dropdown {
      background: #09102E;

      .ivu-menu-item {
        color: #75deef;

        &:hover {

          border-bottom: 2px solid #264e5e;
          background-color: rgba(255, 255, 255, 0);
        }
      }
    }

    .ivu-menu-submenu-title {
      i {
        margin-right: 0;
      }

      .ivu-icon-ios-arrow-down {
        display: none;
      }
    }
  }
}

.page {
  box-sizing: border-box!important;
    height: calc(~ '100% - 20%');
}
</style>
