<template>
    <div id="app">
        <router-view/>
    </div>
</template>

<script>
export default {
    name: 'App',
    data() {
        return {
            resizeFn: null
        }
    },
    mounted() {
        let width=document.body.width();
        if(width>=1200){
          let fontsize=width/1920*40;//fontsize为当前屏幕的基数字体，相对于设计稿计算得到的。
          document.html.css("font-size",`${fontsize}px`)
        }//当加载页面的时候设置生效
        window.onresize = () => {//当页面尺寸改变的时候生效
          return (() => {
            let width=document.body.width();
            if(width>=1200){
              let fontsize=width/1920*40;
              document.html.css("font-size",`${fontsize}px`)
            }
          })()
        }
      // pageResize();
        const documentWidth = document.body.offsetWidth;
        const ratio = documentWidth / 1920;
        if (documentWidth > 1920) {
            document.body.style.transform = `scale(${ratio}, ${ratio})`
        }
        this.resizeFn = this.$debounce(function () {
            const documentWidth = document.body.offsetWidth;
            const ratio = documentWidth / 1920;
            if (documentWidth > 1920) {
                document.body.style.transform = `scale(${ratio}, ${ratio})`
            }
        }, 200)
        window.addEventListener('resize',this.resizeFn);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.resizeFn);
    }
}
</script>

<style lang="less">
html,body {
    height: 100%;
    width: 100%;
    padding:0;
    margin:0;
    //background: url("./assets/pageBg.png") no-repeat center center;
   background: #03044A!important;
}
html {
    font-size: 20px;
}
body {
    transform-origin: left top;
    background-size: 100% 100%;
}
#app {
    height: 100%;
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
</style>
